import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import Button from "../../Components/UI-Elements/Button/Button";
import ViewBox from "../../Components/Viewbox/Viewbox";
import EventViewBox from "../../Components/Viewbox/EventViewBox";
import SkeletonLoader from "../../Components/UI-Elements/SkeletonLoader/SkeletonLoader";
import useFetchEvents from "../../Utils/Hooks/useFetchEvents";
import useFetchGalleryImages from "../../Utils/Hooks/useFetchGalleryImages";
import useFetchVideos from "../../Utils/Hooks/useFetchVideos";
import { MdEventBusy } from "react-icons/md";

import { motion } from "framer-motion";

import "./Home.scss";

const HomePage = () => {
  const { events, selectedEvent, setSelectedEvent } = useFetchEvents();
  const { galleryImages = [], loading: galleryLoading } =
    useFetchGalleryImages();
  const videos = useFetchVideos();
  const [currentVideo, setCurrentVideo] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [author, setAuthor] = useState("");
  const [authorLink, setAuthorLink] = useState("");
  const [artist, setArtist] = useState("");
  const [artistLink, setArtistLink] = useState("");
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate("/gallery");
  };

  const handleNextVideo = () => {
    setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length);
  };

  const handlePrevVideo = () => {
    setCurrentVideo(
      (prevVideo) => (prevVideo - 1 + videos.length) % videos.length
    );
  };

  const closeEventViewBox = () => {
    setSelectedEvent(null);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image.url);
    setAuthor(image.author);
    setAuthorLink(image.authorLink);
    setArtist(image.artist);
    setArtistLink(image.artistLink);
  };

  const closeViewBox = () => {
    setSelectedImage(null);
    setAuthor("");
    setAuthorLink("");
    setArtist("");
    setArtistLink("");
  };

  return (
    <>
      <motion.div
        className="page-outline"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{
          opacity: 0,
          x: 100,
          transition: { duration: 0.2, ease: "easeOut" },
        }}
      >
        <div className="content">
          <div className="topSection">
            <div className="events">
              <h3>Upcoming Events</h3>
              <div className="eventList">
                <ul>
                  {events.length > 0 ? (
                    events.map((event, index) => (
                      <li key={index}>
                        <div className="eventTitle">
                          <strong>{event.title}</strong>
                        </div>
                        <div className="eventDate">{event.formattedDate}</div>
                        <a
                          href={event.ticketLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Buy Tickets
                        </a>
                        <div className="event-image">
                          <img
                            src={event.displayImage}
                            alt={event.title}
                            style={{ width: 160, height: 100 }}
                            loading="lazy"
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="no-events-message">
                      <MdEventBusy size={80} style={{ color: "#ccc" }} />
                      <h4>No Upcoming Events</h4>
                      <p>
                        It looks like we don't have any events scheduled right
                        now.
                      </p>
                      <p>Check back later or explore our other content!</p>
                      <Button
                        variant="primary"
                        label="Explore Gallery"
                        onClick={handleViewMoreClick}
                      />
                    </div>
                  )}
                </ul>
              </div>
            </div>

            <div className="gallery">
              <h3>Gallery</h3>
              <div className="image-grid">
                {galleryLoading
                  ? Array.from({ length: 4 }).map((_, index) => (
                      <div key={index} className="image-item">
                        <SkeletonLoader width="100%" height="250px" />
                      </div>
                    ))
                  : galleryImages?.map((image, index) => (
                      <div
                        key={index}
                        className="image-item"
                        onClick={() => handleImageClick(image)}
                      >
                        <img
                          className="galleryImage"
                          src={image.url}
                          alt={`${image.artist} at Dog Pit by ${image.author}`}
                          loading="lazy"
                        />
                      </div>
                    ))}
              </div>
              <Button
                variant="primary"
                label="View More"
                onClick={handleViewMoreClick}
              />
            </div>
          </div>

          <div className="albumSection">
            <h2>CANDYCORE OUT NOW!</h2>
            <div className="spotify-player">
              <iframe
                title="spotidy-player"
                src="https://open.spotify.com/embed/album/6NHfT3zS4jGOlu4EgPPf03?utm_source=generator&theme=0"
                width="100%"
                height="352"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
          </div>

          <div className="heroSection">
            <h3>Featured Videos</h3>
            <div className="videoWrapper">
              {videos.length > 0 ? (
                <motion.div
                  key={currentVideo}
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -300, opacity: 0 }} 
                  transition={{ duration: 0.5, ease: "easeInOut" }} 
                  style={{ width: "100%", height: "100%" }}
                >
                  <iframe
                    width="1280"
                    height="720"
                    src={videos[currentVideo]}
                    title="Featured Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </motion.div>
              ) : (
                <SkeletonLoader width="1280px" height="720px" />
              )}
            </div>
            <div className="videoControls">
              <Button
                variant="primary"
                label="Prev"
                onClick={handlePrevVideo}
              />
              <span>{`${currentVideo + 1} / ${videos.length}`}</span>
              <Button
                variant="primary"
                label="Next"
                onClick={handleNextVideo}
              />
            </div>
          </div>

          <div className="bottomSection">
            <div className="contact">
              <h3>Contact Us</h3>
              <div className="contact-info">
                <p>
                  <FaInstagram />{" "}
                  <a
                    href="https://www.instagram.com/rarecandyz_dogpit/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @rarecandyz_dogpit
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      {selectedImage && (
        <ViewBox
          imageSrc={selectedImage}
          author={author}
          authorLink={authorLink}
          artist={artist}
          artistLink={artistLink}
          onClose={closeViewBox}
        />
      )}
      {selectedEvent && (
        <EventViewBox event={selectedEvent} onClose={closeEventViewBox} />
      )}
    </>
  );
};

export default HomePage;
