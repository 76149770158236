import React from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import ProtectedRoute from "../../Utils/Auth/AuthRoute";
import HomePage from "../../Pages/Home/Home";
import InfoPage from "../../Pages/Info/Info";
import GalleryPage from "../../Pages/Gallery/Gallery";
import LoginPage from "../../Pages/Login/Login";
import DashboardPage from "../../Pages/DashboardPage/DashBoard";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardPage />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
