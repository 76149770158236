import React, { useState, useEffect } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import ViewBox from "../../Components/Viewbox/Viewbox";
import SkeletonLoader from "../../Components/UI-Elements/SkeletonLoader/SkeletonLoader";
import "./GalleryPage.scss";

import { motion } from "framer-motion";

const GalleryPage = () => {
  const [images, setImages] = useState([]);
  const [imageLoaded, setImageLoaded] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [author, setAuthor] = useState("");
  const [authorLink, setAuthorLink] = useState("");
  const [artist, setArtist] = useState("");
  const [artistLink, setArtistLink] = useState("");

  const handleImageLoad = (index) => {
    setImageLoaded((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = true;
      return updatedState;
    });
  };

  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const storageRef = ref(storage, "images/");
      const result = await listAll(storageRef);

      const imageCollection = collection(db, "galleryImages");
      const imageSnapshot = await getDocs(imageCollection);
      const imageMetaData = imageSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const urls = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          const metadata = imageMetaData.find((meta) => meta.imageUrl === url);
          return {
            url,
            author: metadata ? metadata.author : "",
            authorLink: metadata ? metadata.authorLink : "",
            artist: metadata ? metadata.artist : "",
            artistLink: metadata ? metadata.artistLink : "",
          };
        })
      );
      setImages(urls);
      setLoading(false);
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      setImageLoaded(Array(images.length).fill(false));
    }
  }, [images]);

  const handleImageClick = (image) => {
    setSelectedImage(image.url);
    setAuthor(image.author);
    setAuthorLink(image.authorLink);
    setArtist(image.artist);
    setArtistLink(image.artistLink);
  };

  const closeViewBox = () => {
    setSelectedImage(null);
    setAuthor("");
    setAuthorLink("");
    setArtist("");
    setArtistLink("");
  };

  return (
    <>
      <motion.div
        className="gallery-page"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{
          opacity: 0,
          x: 100,
          transition: { duration: 0.2, ease: "easeOut" },
        }}
      >
        <header className="gallery-header">
          <h1>Event Gallery</h1>
          <p>Explore moments captured from our past events.</p>
        </header>
        <div className="gallery-grid">
          {loading
            ? Array.from({ length: 30 }).map((_, index) => (
                <div key={index} className="gallery-item">
                  <SkeletonLoader width="100%" height="200px" />
                </div>
              ))
            : images.map((image, index) => (
                <div
                  key={index}
                  className="gallery-item"
                  onClick={() => handleImageClick(image)}
                >
                  <img
                    src={image.url}
                    alt={`Event ${index + 1}`}
                    loading="lazy"
                  />
                </div>
              ))}
        </div>
      </motion.div>
      {selectedImage && (
        <ViewBox
          imageSrc={selectedImage}
          author={author}
          authorLink={authorLink}
          artist={artist}
          artistLink={artistLink}
          onClose={closeViewBox}
        />
      )}
    </>
  );
};

export default GalleryPage;
